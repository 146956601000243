table.table.custom-table1.cart-ticket {
	margin-bottom: 0px;
}
table.table.custom-table1.cart-ticket .table.inner-table {
	margin-bottom: 0px;
}
tr.light-blue span {
	color: #666666;
	font-size: 16px;
	font-weight: 700;
	margin-right: 10px;
	text-transform: capitalize;
}
.custom-table1.cart-ticket tr th {
	text-align: left;
	color: #001f30;
	font-size: 16px;
	font-weight: 700;
	padding: 13px;
	border: 0;
}
table.table.custom-table1.cart-ticket tr td {
	padding: 14px 10px;
	border-bottom: 1px solid #ddd;
}
tr.light-blue span.due-amount {
	margin-right: 0;
}
table.table.custom-table1.cart-ticket .table.inner-table tr th {
	padding: 13px 5px;
}
table.table.custom-table1.cart-ticket tr td.minus-plus {
	// text-align: right;
	width: 30%;
}
.custom-table1.cart-ticket thead tr th {
	text-transform: uppercase;
}
tr.light-blue th {
	color: #ffffff;
}
.custom-table1 tr th {
	color: #0060a9;
	font-size: 14px;
	font-weight: 700;
}
.custom-table1 tr th,
.custom-table1 tr td {
	text-align: left;
	border: 0;
	vertical-align: middle;
}
.custom-table1 tr td:first-child {
	width: 22%;
}
.ticket-details {
	width: 500px !important;
}
.shopping-cart {
	display: inline-block;
	font-size: 16px;
	color: #666666;
	font-weight: bold;
	text-transform: uppercase;
	width: 140px;
}
.shopping-cart .devider {
	margin: 20px 0 6px;
	width: 100%;
	height: 1px;
	background: #c8c8c9;
	display: block;
}
.shopping-cart .psnger {
	display: inline-block;
	color: #001f30;
	text-transform: uppercase;
	width: 130px;
	font-size: 16px;
}
.shopping-cart .age {
	display: inline-block;
	color: #509df7;
	font-size: 16px;
	width: 100px;
}
.shopping-cart .plr0 {
	padding-left: 0;
	padding-right: 0;
}
.qus-view img {
	margin-top: 5px;
	position: relative;
}
.right-arrow {
	float: right;
}
.shopping-cart h2 {
	text-transform: capitalize;
}
// .shopping-cart-new-search button.btn.btn-secondary {
//   border: 0 !important;
//   height: auto;
//   width: auto;
//   padding: 8px 15px 8px 8px;
//   margin: 0;
//   font-size: 14px;
//   color: #0077CC;
//   font-weight: 700;
//   position: relative;
// }
// .shopping-cart-new-search button.btn.btn-secondary:focus,
// .shopping-cart-new-search button.btn.btn-secondary:active {
//   box-shadow: none !important;
//   outline: none !important;
// }
// .shopping-cart-new-search button.btn.btn-secondary:after {
//   position: absolute;
//   content: "";
//   right: 0;
//   border: solid #0077CC;
//   border-width: 0 2px 2px 0;
//   display: inline-block;
//   padding: 5px;
//   transform: rotate(-45deg);
//   -webkit-transform: rotate(-45deg);
//   top: 13px;
// }
.minus {
	width: 26px;
}
.persion-count {
	display: inline-block;
	width: 44px;
	font-weight: 400;
	font-size: 16px;
}

.plus {
	display: block;
	width: 40px;
}

.containerbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 120px;
}

.border0 {
	border: 0 !important;
}
.padding0 {
	padding: 0 !important;
}
.mt15 {
	margin-top: 15px !important;
}
.custom-table1.cart-ticket tr th.shoping-cart {
	color: #fff;
	background: #7ea5c3;
	text-align: left;
	padding-left: 25px;
}
.checkout-btn {
	background: #279cf7;
	padding: 12px;
	width: 260px;
	height: 50px;
	display: block;
	margin: 0 auto;
	color: #fff;
	text-transform: uppercase;
	border: solid 1px #279cf7;
	border-radius: 30px;
	font-size: 16px;
	font-weight: 700;
}
.checkout-btn:hover {
	background: #279cf7;
	color: #fff;
	text-decoration: none;
}
.search-btn {
	border: solid 2px #279cf7;
	border-radius: 30px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 10px 26px;
	float: right;
	background: #279cf7;
}
.search-btn:hover {
	color: #ffffff;
	outline: none;
	box-shadow: none;
}
.custom-table1 tr td.border-btm1 {
	border-bottom: 1px solid #dee2e6;
}
.due-amount {
	font-size: 18px;
	font-weight: 700;
	color: #4c6070;
}
.custom-table1.cart-ticket.amount-tble {
	margin-top: 15px;
}
.custom-table1.cart-ticket.amount-tble tr td {
	padding: 10px 0;
}
.minus img,
.plus img {
	max-width: 26px;
	cursor: pointer;
}
button.checkout-btn:disabled {
	border: 1px solid #a8a8a8 !important;
}
.fare {
	margin-left: -53px;
	text-align: right !important;
	font-size: 18px;
	font-weight: 400;
}

.mobile-cart-ticket,
.mobile-cart-ticket-header,
.shopping-cart-new-search-mobile {
	display: none;
}

.light-blue {
	background-color: #e5eff6 !important;
}
.padding-8 {
	padding: 8px;
}
.padding-16 {
	padding: 16px;
}
.max-width {
	width: 100%;
}
@media (max-width: 991px) {
	.cart-header {
		display: none !important;
	}
	.shopping-cart h2 {
		text-transform: uppercase;
		font-weight: bold;
	}
	.max-width {
		display: none;
	}
	.sticky-footer-total {
		float: left;
		position: relative;
		top: 5px;
	}
	.cart-footer {
		color: #4c6070;
		font-size: 18px;
		font-weight: 700;
		margin-right: 10px;
		text-transform: uppercase;
	}
	.mobile-cart-ticket {
		display: block !important;
	}
	.mobile-cart-ticket-header {
		display: block;
		width: 100%;
		height: 68px;
		padding: 10px;
		font-size: 16px;
		color: #666666;
		background-color: #c3d5e2;
	}
	.light-blue {
		background-color: #c3d5e2 !important;
	}
	.mobile-cart-ticket-header-title {
		color: #4c6070;
		font-weight: bold;
		font-size: 16px;
		width: 100px;
		display: inline-block;
	}
	.mobile-cart-ticket-header-div {
		height: 24px;
	}
	.mobile-orgin-dest-label {
		color: #0060a9;
		font-weight: bold;
		text-transform: uppercase;
	}
	.ticket-type,
	.psnger,
	.age {
		display: block !important;
	}
	.ticket-details,
	.shopping-cart .ticket-type,
	.shopping-cart .psnger,
	.shopping-cart .age {
		width: 90px !important;
	}
	.minus-plus,
	.fare {
		padding-top: 28px !important;
	}
	.table.table.custom-table1.cart-ticket tr td.fare {
		font-size: 16px;
		color: #001f30;
	}

	.shopping-cart h1 {
		color: #0060a9;
		font-size: 24px;
		text-transform: uppercase;
	}
	// .shopping-cart-new-search {
	//   display: none;
	// }
	// .shopping-cart-new-search-mobile {
	//   display: inline;
	//   float: right;
	//   width: 120px;
	//   height: 34px;
	//   border: 1px solid #279CF7;
	//   border-radius: 18px;
	//   color: #0077CC;
	//   font-size: 14px;
	//   font-weight: bold;
	//   text-align: center;
	//   padding-top: 5px;

	// }
	.custom-table1.cart-ticket tr th {
		font-size: 14px;
		font-weight: 700;
	}
	.shopping-cart .psnger {
		float: inherit;
	}
	.shopping-cart h3 {
		line-height: 40px;
		margin-bottom: 20px;
	}
}
.bgWhite .section-padding {
	background: #ffffff;
}

.shopping-table thead tr,
tfoot tr {
	background: #e5eff6;
}

.shopping-table tbody tr {
	border-bottom: 1px solid #dee5ee;
}

.shopping-table thead tr th {
	float: inherit !important;
	width: 100%;
}

.shopping-table thead tr th span:nth-child(odd) {
	font-family: 'Lato' sans-serif;
	font-size: 16px;
	color: #4c6070;
	line-height: 22px;
	font-weight: 700;
	padding-right: 10px;
}

.shopping-table thead tr th span:nth-child(even) {
	font-family: 'Lato' sans-serif;
	font-size: 16px;
	color: #0060a9;
	line-height: 22px;
	font-weight: 700;
	text-transform: uppercase;
	padding-right: 50px;
}
.shopping-table tbody tr td:last-child {
	font-family: 'Lato' sans-serif;
	font-size: 16px;
	color: #001f30;
	line-height: 22px;
	font-weight: 700;
	float: right;
}

.shopping-table tbody tr td:first-child {
	width: 55%;
	position: relative;
}

.shopping-table tbody tr td span:first-child {
	font-family: 'Lato' sans-serif;
	font-size: 16px;
	color: #666666;
	line-height: 22px;
	font-weight: 700;
	position: absolute;
	text-transform: uppercase;
}

.shopping-table tbody tr td span:nth-child(2) {
	font-size: 16px;
	color: #001f30;
	line-height: 22px;
	font-family: 'Lato' sans-serif;
	text-transform: uppercase;
	position: absolute;
	left: 160px;
}

.shopping-table tbody tr td span:nth-child(3) {
	font-size: 16px;
	color: #509df7;
	line-height: 22px;
	font-family: 'Lato' sans-serif;
	position: absolute;
	left: 250px;
}

.shopping-cart .age {
	display: inline-block;
	color: #509df7;
	font-size: 16px;
	width: auto;
}

// .shopping-table tbody tr td span:nth-child(4) {
// 	font-size: 16px;
// 	color: #4C6070;
// 	line-height: 22px;
// 	font-family: 'Lato' sans-serif;
// 	font-weight: normal;
// 	position: absolute;
// 	left: 560px;
// }
.shopping-table tbody tr td span:last-child {
	font-size: 16px;
	color: #4c6070;
	line-height: 22px;
	font-family: 'Lato' sans-serif;
	position: absolute;
	right: 0;
	font-weight: normal;
	width: 150px;
	text-transform: capitalize;
}

.shopping-table tfoot tr th:first-child {
	font-size: 16px;
	color: #4c6070;
	line-height: 22px;
	font-weight: 700;
	font-family: 'Lato' sans-serif;
}
.shopping-table tfoot tr th:last-child {
	font-size: 16px;
	color: #4c6070;
	line-height: 22px;
	font-weight: 700;
	float: right;
	font-family: 'Lato' sans-serif;
}

.shcrt {
	margin-bottom: 0;
	margin-top: 20px;
}

.minus-plus .shpcrt-min-pls:first-child,
.shpcrt-min-pls:last-child {
	img {
		width: 26px;
		cursor: pointer;
	}
}
.minus-plus .shpcrt-min-pls .qnty {
	color: #001f30;
	font: 500 16px/20px Lato;
	padding-top: 0px;
	width: 90px;
	text-align: center;
}

span:final-admin-fee-price,
span:final-admin-fee-label {
	font-size: 16px;
	color: #4c6070;
	font-weight: normal;
	margin-right: 0;
}

.bottomTh {
	padding: 0 .75rem !important;
}

.shopbackticket {
	background: #f2f3f4 !important;
	font: 700 16px Lato;
}

.shoptickdetail {
	padding-right: 98px;
}
