@import "~bootstrap/scss/bootstrap.scss";
@font-face {
	font-family: 'Lato';
	font-weight: 500;
	src: url(../assets//fonts/Lato-Regular.ttf);
}
html {
	min-height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Lato", sans-serif !important;
	background-color: #edeff0;
	min-height: 100%;
	font-size: 14px;
	font-weight: 400;
	color: #001F30;
}
.root {
	font-family: "Lato", sans-serif;
	font-size: 14px;
	height: 100%;
}
.section-padding {
	padding-top: 80px;
	padding-bottom: 0;
}

.base-height {
	min-height: 820px;
	height: 100%;
}

/*-- Custom Modal Popup --*/
.modal-backdrop.show {
	opacity: 0.54;
	background: #e2e2e2;
}
.custom-modal-popup {
	opacity: 1;
}
.custom-modal-popup-email .modal-dialog {
	width: 50px;
	height: 800px;
	max-width: 800px;
	color: white;
	margin: auto;
	border: 1;
	border-radius: 6px;
	opacity: 1;
	background: #fff;
	top: 15%;
}

.custom-modal-popup .modal-dialog {
	width: 100%;
	max-width: 800px;
	margin: auto;
	border: 0;
	border-radius: 6px;
	opacity: 1;
	background: #fff;
	top: 15%;
}
.custom-modal-popup .modal-content {
	border: 0;
	padding: 15px;
	background: #ffffff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.30);
	border-radius: 3px;
}

.modal-footer {
	justify-content: center;
	border: transparent;
}

.span-as-anchor {
	font-size: 16px;
	color: #303639;
	letter-spacing: 0;
	line-height: 17px;
	cursor: pointer;
}

.App-logo {
	width: 292px;
	height: 154px;
	pointer-events: none;
	margin: 30px 0 30px 0;
}

.App-header {
	background-image: url('../assets/images/splash_background.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	min-height: 100vh;
	align-items: center;
	font-size: calc(10px + 2vmin);
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.App-Center{
	display: flex;
    flex-flow: column;
    align-items: center
}
.main-text {
	font-size: 42px;
	letter-spacing: 0;
	color: #0461a9;
  line-height: 44px;
  margin-bottom: 4rem;
}
.footer-powered-text {
	font-size: 12px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: 16px;
}
.footer-version-text {
	opacity: 0.9;
	font-size: 12px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: 22px;
}

// Orbit Pages
@import "../pages/agentportal/style/index.scss";

button:focus {
	outline: none !important;
}