.table.ticket-fare-table tr th {
	border-top: 0;
	font-size: 14px;
	font-style: italic;
	color: #279cf7;
	padding: 20px 5px 8px;
}
.table.ticket-fare-table {
	position: relative;
}
.table.ticket-fare-table tr {
	border-bottom: 1px solid #d0dbe3;
}
.table.ticket-fare-table tr:last-child {
	border-bottom: 0;
}
.table.ticket-fare-table tr td {
	border: 0;
	border-bottom: 0;
	vertical-align: middle;
	padding: 22px 0px;
}
.table.ticket-fare-table tr td:first-child {
	width: 32%;
}
.ticket-title {
	color: #fff !important;
}
.ticket-fare .origin span,
.ticket-fare .destination span {
	color: #001f30;
	font-size: 18px;
	font-weight: 400;
	margin-right: 12px;
	text-transform: none;
}
.ticket-fare .origin,
.ticket-fare .destination {
	color: #001f30;
	font-size: 16px;
	font-weight: bold;
	margin: 0 16px 0 0;
}
.ticket-fare .destination span {
	margin-left: 0;
}
.search_btn button.btn {
	width: 253px !important;
	height: 27px !important;
	text-align: right !important;
	border: 0;
	padding: 3px 12px !important;
	float: right;
	background: transparent;
	text-decoration: underline;
	font: 700 14px/18px Lato;
	letter-spacing: 0.35px;
	color: #1592e6;
	position: relative;
}
.search_btn button.btn:after {
	position: absolute;
	content: "";
	right: 0;
	border: solid #1592e6;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 4.5px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	top: 28%;
}
.search_btn button.btnn:active {
	background: transparent;
	outline: none;
	box-shadow: none;
}
.search_btn button.btnn.btn-primary:not(:disabled):not(.disabled):active,
.search_btn button.btnn.btn-primary:not(:disabled):not(.disabled).active,
.show > .search_btn button.btnn.btn-primary.dropdown-toggle {
	color: #1592e6;
	background-color: transparent;
	border-color: #005cbf;
	box-shadow: none;
}
.ticket-fare .nav-tabs .nav-item a.nav-link {
	background-color: #ffffff;
	font-size: 14px;
	text-align: left;
	font-weight: 700;
	text-transform: uppercase;
	color: #0060a8;
	padding: 10px 9px 9px;
	margin-bottom: 0;
	border: solid 1px #d0dbe3;
}
.ticket-fare .nav-tabs .nav-item a.nav-link:hover {
	color: #0060a8 !important;
}
.ticket-fare .nav-tabs .nav-item a.nav-link.active {
	background-color: #0060a8;
	color: #fff;
	border: solid 1px #0060a8;
}
.ticket-fare .nav-tabs .nav-item a.nav-link.active:hover {
	background-color: #0060a8 !important;
	color: #fff !important;
}
.ticket-fare .nav.nav-tabs.nav-fill {
	margin-top: 5px;
}
.ticket-fare .nav-tabs .nav-item.first-tab a.nav-link {
	border-right: 0;
}
.ticket-fare .nav-tabs .nav-item.last-tab a.nav-link {
	border-left: 0;
}
.ticket-fare .psnger {
	text-transform: uppercase;
	color: #001f30;
	font: 600 16px/20px Lato;
}
.ticket-fare .age {
	float: left;
	width: 100%;
	font: 400 Italic 15px/14px Lato;
	letter-spacing: 0;
	color: #0077cc;
}
.ticket-fare .psnger-fare {
	font: 400 18px/30px Lato;
	letter-spacing: 0;
	color: #001f30;
}

.ticket-fare .textdata.row {
	margin: 5px 15px 10px;
}
.ticket-fare .amount.card-footer {
	margin-bottom: 0;
}
.amount {
	margin: 15px 0 0px 0;
	font: 700 15px/27px Lato;
	letter-spacing: 0.18px;
	color: #001f30;
}
.ticket-fare .order-summary-box .order-summary {
	border-bottom: solid 1px #d0dbe3;
	padding-bottom: 5px;
	margin-bottom: 2px;
}
.ticket-fare .order-summary-box .details {
	margin-top: 5px;
	padding-bottom: 10px;
	min-height: 240px;
}
.ticket-fare .devider {
	margin: 20px 15px 6px 15px;
	width: auto;
	height: 1px;
	background: #d0dbe3;
}
.spinner-border {
	position: absolute;
	left: 47%;
	top: 57%;
	z-index: 100;
}
.relative {
	position: relative;
}

.bottom-text {
	font: 400 14px/18px Lato;
	letter-spacing: 0.14px;
	color: #001f30;
}

.order-summary-box .order-summary h4 {
	font: 700 18px Lato;
	letter-spacing: 0.18px;
	color: #001f30;
	margin-bottom: 4px;
	margin-top: 0;
}

.order-summary-box .order-summary h3 {
	font: 400 24px/20px Lato;
	letter-spacing: 0.18px;
	color: #001f30;
	margin-bottom: 4px;
	margin-top: 0;
}

.ticket-fare .card {
	border: solid 1px #d0dbe3;
	border-radius: 9px;
}

.order-summary-box {
	border: solid 1px #d0dbe3;
	border-radius: 9px;
	min-height: 400px;
}

.order-summary-box a {
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	color: #279cf7;
	line-height: 22px;
	// text-transform: uppercase;
}
.order-summary-box a img {
	max-width: 6px;
	margin-left: 5px;
	margin-top: -2px;
}
.order-summary-box .order-summary {
	border-bottom: solid 1px #c8c8c9;
	padding-bottom: 12px;
}
.order-summary-box .details {
	margin-top: 5px;
}
.order-summary-box .details h4 {
	margin-bottom: 0;
	font: 400 14px Lato;
	letter-spacing: 0.14px;
	color: #001f30;
}
.origin-name,
.destination-name {
	margin-bottom: 5px;
	display: block;
	text-transform: uppercase;
	font: 700 14px/25px Lato;
	letter-spacing: 0.14px;
	color: #0060a8;
}
.num-of-ticket,
.one_way {
	font: 700 16px/22px Lato;
	letter-spacing: 0.16px;
	color: #001f30;
}
.num-of-ticket {
	min-width: 25px;
	display: block;
	text-align: center;
}
.one_way {
	min-width: 90px;
	display: block;
	text-transform: uppercase;
}
.pasngr-type {
	font: 700 14px/22px Lato;
	letter-spacing: 0.16px;
	color: #001f30;
	text-transform: uppercase;
	margin-left: 10px;
}
.row-box .col-md-4,
.row-box .col-md-3 {
	padding: 0;
}
span.price {
	font: 700 16px/22px Lato;
	letter-spacing: 0.16px;
	color: #263238;
}
.devider {
	margin: 20px 0 6px;
	width: 100%;
	height: 1px;
	background: #c8c8c9;
}
.amount.card-footer {
	background: #e5eff6;
	border: 0;
	margin: 15px;
}
button.btn.keep-button {
	color: #279cf7;
	background: transparent;
	border: 2px solid #279cf7;
	padding: 11px;
	width: 100%;
	border-radius: 4px;
	font-size: 16px;
	font-weight: bold;
}
.order-summary-box .button.row {
	margin-top: 5px;
}
.order-listing {
	padding-left: 0;
	list-style-type: none;
	margin: 0;
	width: 100%;
}
.order-listing li {
	display: inline-block;
	margin-right: auto;
}
.order-listing li:nth-child(2) {
	min-width: 32%;
}
.order-listing li:last-child {
	margin-right: 0px;
	float: right;
	padding-right: 10px;
}
button.payment-btn {
	min-height: 50px;
	background: #279cf7;
	border: 1px solid #279cf7;
	border-radius: 25px;
	width: 238px;
	font: Bold 16px/19px Lato;
	letter-spacing: 0.16px;
	color: #ffffff;
	margin: 0 auto;
	text-transform: uppercase;
}
button.payment-btn:hover,
button.payment-btn:active {
	min-height: 50px;
	background: #279cf7;
	border: 1px solid #279cf7;
	box-shadow: none;
}

.origin,
.destination {
	margin-right: 15px;
}

.origin-label-ticket,
.destination-label-ticket {
	display: none;
	font-size: 17px;
}
.cart-mail-box {
	margin: 10px 30px 0 30px;
	text-align: center;
}
.cart-mail-box .form-control {
	height: 52px;
	padding: 25px 15px 6px 15px !important;
}
.cart-mail-box .form-control-placeholder.form-label {
	margin: 0;
	position: absolute;
	top: 4px;
	left: 11px;
}
.cart-mail-box .bottom-text {
	margin-bottom: 10px;
	display: block;
	position: relative;
}
.order-summary-box input.form-control {
	max-width: 200px;
	margin: auto;
}
.cart-mail-box .form-control-placeholder.form-label {
	left: 48px;
}

.cart-mail-box .error-msg {
	background: #ffe6e6;
	padding: 10px 12px !important;
	font-size: 14px;
	color: #bf1111;
	line-height: 20px;
	margin-left: 26px;
	max-width: 200px;
	text-align: initial;
}

.cart-mail-box .error-msg::before {
	display: none;
}

.order-summary,
.details,
.amount,
.button,
.textdata {
	margin-left: 15px;
	margin-top: 15px;
	margin-right: 15px;
}

.ticket-hints {
	margin-left: 5px;
}

/*-- Mobile device --*/
@media (max-width: 991px) {
	.order-summary-box input.form-control {
		max-width: 100%;
	}
	.order-summary-box .cart-mail-box .form-control-placeholder.form-label {
		left: 30px;
	}
	.section-padding {
		padding: 25px 0 !important;
	}
	.origin-label-ticket,
	.destination-label-ticket {
		display: inline;
	}
	.ticket-fare .nav-tabs .nav-item a.nav-link {
		padding: 9px;
	}
	.sticky-footer {
		padding: 10px 15px;
		background: #0060a8;
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 50px;
		left: 0;
	}
	.sticky-footer .order-listing li {
		font: 400 16px/22px Lato;
		letter-spacing: 0.16px;
		color: #ffffff;
		margin-right: 20px;
		min-width: auto;
	}
	.sticky-footer .order-listing li:last-child {
		margin-right: 0;
		float: right;
	}
	button.buy-now-btn {
		background: #ffffff;
		border: 1px solid #ffffff;
		border-radius: 25px;
		width: 100% !important;
		height: 30px !important;
		float: right;
		cursor: pointer;
		margin: 0;
		font: 700 13px/19px Lato;
		letter-spacing: 0.16px;
		color: #3c77cc;
		text-transform: uppercase;
		padding: 5px 20px;
	}
	.order-summary-box {
		min-height: auto;
		margin-bottom: 10px;
		margin-top: 15px;
		padding: 0 0 10px 0;
	}
	.ticket-fare .origin,
	.ticket-fare .destination {
		font-size: 14px;
	}
	.ticket-fare .destination span {
		margin-left: 0;
	}
	.ticket-fare .origin span,
	.ticket-fare .destination span {
		margin-right: 5px;
		font-size: 12px;
	}
	.device-bg {
		background: #d0deed;
		padding: 15px 0;
	}
	.search_btn {
		display: none;
	}
	.order-summary-box .details h4 {
		font: 400 14px Lato;
	}
	.ticket-fare .nav.nav-tabs.nav-fill {
		margin-top: 10px;
		margin-left: -15px;
		margin-right: -15px;
	}
	.ticket-fare .nav-tabs .nav-item a.nav-link {
		border-radius: 0;
		padding: 13px 10px;
		font-weight: 400;
		text-align: center;
	}
	.ticket-fare .nav-tabs .nav-item.first-tab a.nav-link {
		border-left: 0;
	}
	.ticket-fare .nav-tabs .nav-item.last-tab a.nav-link {
		border-right: 0;
	}
	.ticket-fare .age {
		font-size: 14px;
		font-style: normal;
	}

	.ticket-fare .persion-count {
		width: 26px !important;
		margin-right: 0 !important;
		font-size: 16px;
		font-weight: 400;
		vertical-align: middle;
	}
	.ticket-fare .psnger-fare {
		font: 400 16px/30px Lato;
	}
	.order-summary-box .order-summary h3 {
		font: 400 20px/20px Lato;
		color: #001f30;
	}
	.origin-name,
	.destination-name {
		font: 700 14px/20px Lato;
		color: #0060a9;
	}
	.order-summary-box .amount {
		font: 700 14px/27px Lato;
		color: #001f30;
	}
	.cart-mail-box {
		margin: 10px 0px 0 0px;
	}
}

.bs-labels-align {
	text-align: right;
}
.containerbox {
	float: right;
}
.minus img {
	height: 26px;
	cursor: pointer;
	margin-right: 20px !important;
}

.plus img {
	height: 26px;
	cursor: pointer;
}
.busfare-btn {
	margin: 10px;
}
.p-r-5 {
	padding-right: 5px;
}

.ticketfare .container {
	max-width: 1240px;
}

.ticketfare .persion-count {
	color: #001f30;
	font: 400 16px/20px Lato;
}

/**
 * Admin Fee 
 */

.shopping-cart-admin-free {
	li {
		margin-bottom: 15px;
		vertical-align: top;
		min-width: 39px;
		.admin_fee_label,
		.admin_fee_price {
			color: #001f30;
			font-size: 12px;
		}
		.admin_fee_price {
			margin-bottom: 3px;
		}
	}
}

.final-admin-fee-label,
.final-admin-fee-price {
	font-size: 14px;
	color: #001f30;
	font-weight: normal;
}

.ticketdetails {
	display: flex;
	background: #f2f3f4;
	flex-direction: row;
	font: 700 14px/20px Lato;
	padding: 5px;
	.quanity {
		margin-right: 14px;
	}
	.ticket {
		margin-right: 70px;
	}
	.passenger {
		margin-right: 61px;
	}
}

.emptytickettext {
	font-family: Lato-Bold;
	font-size: 18px;
	color: #001f30;
	letter-spacing: 0;
	text-align: center;
	line-height: 30px;
}

.emptyiconcenter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 246px;
	height: 100%;
}
