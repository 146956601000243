/* The radio-label */
.radio-label {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}
.radio-label > span {
  font-size: 16px;
}

/* Hide the browser's default radio button */
.radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 36px;
  width: 36px;
  border: 3px solid #1a82c7;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
// .radio-label:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the radio button is checked, add a blue background */
// .radio-label input:checked ~ .checkmark {
//   // background-color: #2196f3;
// }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-label .checkmark:after {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #0461a9;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
