.table-responsive.cashout_tb {
	width: 65%;	
}
.cash-divider {
	background: #e5eff6;
	min-height: 40px;
}

.main-area {
	margin-top: 20px;
}

.main-area .agent-id-cashout {
	color: #001f30;
	font: 500 20px/15.2px Lato;
	padding-top: 13px;
}

.main-area table {
	width: 100%;
	font-family: Lato, sans-serif;
	margin-top: 59px;
	table {
		width: 100%;
		margin-top:0
	}
}

.main-area thead tr th:first-child,
tbody tr td:first-child {
	width: 37%;
}
tbody tr td:first-child[colspan="3"] {
	width: 100%;
}

.main-area .summary {
	font: 600 28px/26px Lato;
	color: #001f30;
}

.main-area thead tr th:first-child {
	font: 700 19px/22px Lato;
}

.main-area thead tr th:not(:first-child) {
	font-size: 18px;
	padding-left: 10px;
}

.main-area tbody tr td:not(:first-child) {
	color: #279cf7;
	letter-spacing: 0.5px;
	font-size: 18px;
	padding-left: 10px;
	font-weight: bold;
}
.main-area tbody tr.bg-light td:not(:first-child) { 
	color: #000;
}

.main-area tbody tr td:first-child {
	font-size: 18px;
}
.main-area tbody tr td:first-child[colspan="3"] td:last-child table tr td:last-child,
.main-area tbody tr td:last-child {
	text-align: right;
}
.main-area tbody tr td:first-child[colspan="3"]:last-child {
	text-align: left;
}
.main-area tbody tr td {
	padding-left: 10px;
	padding-right:10px;
}

.main-area tbody .refund td {
	padding-top: 40px;
}
.main-area tbody .table-divider td {
	padding-top: 8px;
	border-bottom: 1px solid #979797;
	float: none !important;
}

.main-area tbody .total td {
	padding-top: 15px;
}

.main-area tbody .total td {
	color: #2f2f35 !important;
	letter-spacing: 0.5px !important;
	font-size: 18px;
}

.main-area tbody .refund td:first-child {
	font-size: 20px;
	font-weight: 600;
	color: #2f2f35;
	letter-spacing: 0.5px;
	line-height: 22px;
}

.main-area tbody .refund td:last-child {
	font-size: 21px;
	color: #000000;
	letter-spacing: 0.5px;
	line-height: 22px;
}

.main-area tbody tr.tickets-list-header td {
	color: #2f2f35;
	letter-spacing: 0.5px;
	font-size: 16px;
	font-weight: 600;
	padding-top: 19px;
}
.main-area tbody tr.tickets-list-header td:not(:first-child) {
	padding-left: 0;
}
.main-area tbody tr.tickets-list-header td:nth-child(2) {
	width: 20%;
}
tbody tr.tickets-list-header td:first-child {
   width: auto;
}
.main-area tbody tr.tickets-list td {
	color: #001f30;
	letter-spacing: 0.5px;
	font: 500 16px/20px Lato;
	padding-top: 15px;
}

.main-area tbody tr.tickets-list td:not(:first-child) {
	padding-left: 0;
}
.main-area tbody tr.tickets-list td:first-child {
	color: #0060a9;
	width: 33%;
}
.main-area tbody tr.tickets-list td:nth-child(2) {
	width: 20%;
}
.main-area tbody tr.bg-light td {
	font-size: 16px;
}

.main-area .btn-area {
	margin-top: 50px;
}
.main-area .btn-area button {
	border: 1px solid #279cf7;
	border-radius: 25px;
	height: 40px;
	width: 150px;
	margin-right: 26px;
	background: #279cf7;
	color: #fff;
	text-transform: uppercase;
}
.main-area .btn-area button.go-back {
	background: #fff;
	font-size: 16px;
	color: #279cf7;
	letter-spacing: 0;
	text-align: center;
	line-height: 22px;
}
.logout-modal {
	top: 25%;
	width: 340px;
	height: 340px;
	left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;
}
.logout-modal .modal-content,
.modal-header {
	border: 0;
	padding: 0;
}
.logout-modal .modal-header .close {
	padding: 0 1.5rem;
	margin: -1.5rem -1rem -1rem auto;
}

.logout-modal .modal-body {
	padding: 20px;
}
.logout-modal .modal-body img {
	margin: auto;
	justify-content: center;
	display: flex;
	margin-bottom: 15px;
}

.logout-modal .modal-body p {
	font-family: Helvetica;
	font-size: 24px;
	color: #001f30;
	letter-spacing: 0;
	text-align: center;
	line-height: 36px;
	margin: 0;
}

.logout-modal .modal-body button {
	border: 1px solid #279cf7;
	border-radius: 25px;
	height: 40px;
	width: 100%;
	margin-right: 26px;
	background: #279cf7;
	color: #fff;
	display: block;
	text-transform: uppercase;
}
.logout-modal .modal-body .logout-cancel {
	font-size: 16px;
	color: #0077cc;
	bottom: -30px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
}

.cash-out-receipt {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	bottom: 0px;
	margin: auto;
	margin-top: 0px !important;

	.cash-out-container {
		padding: 15px 10px 0 10px;
	}
	.col {
		line-height: 11px;
	}
	.print-ticket-row {
		margin-bottom: 10px;
	}
	h2 {
		font-family: "Lato-Bold";
		color: #000000;
		font-size: 11px;
		text-transform: uppercase;
		margin: 0 5px 0 0;
	}
	.header-wrapper {
		border-bottom: 2px solid #979797;
		padding-bottom: 12px;
		// margin-bottom: 10px;
	}
	label {
		margin: 0;
		padding: 0;
	}
	.bold-text {
		font-weight: bold;
		font-family: "Lato-Bold";
		font-size: 8px;
		color: #001f30;
	}
	.normal-text {
		font-family: "Lato-Regular";
		font-size: 8px;
		color: #263238;
	}
	.receipt-black-color {
		color: #000000;
	}
	.ticket_type {
		margin-right: 5px;
	}
	.receipt-qty {
		margin-right: 10px;
	}
	.right-cell {
		flex: 1;
	}
}

.summary-agentdetail{
	border-radius: 23px;
    background-color: #E5EFF6;
    padding: 5px 17px 5px 17px;
    margin-right: 8px;
}


