.page-break {
	/* page-break-before: always !important; */
	page-break-after: always !important;
}
.print-cont {
	width: 280px;
	margin: 0 0 0 10px;
	font-family: "Verdana", sans-serif;
	font-size: 12px;
	background: #fff;
	padding: 0;
	display: none;
}
@media print {
	.print-cont {
		display: block;
	}
	.ticket_header {
		width: 100%;
		margin-top: -20px;
	}
	.ticket_header tr td:last-child {
		text-align: right;
	}
}

.pr_receipt_detail {
	div {
		display: block;
	}
}

.pr_receipt_total {
	width: 100%;
	margin-bottom: 50px;
	margin-top: 30px;
	border-collapse: collapse;
	thead th,
	td {
		border-bottom: 1px solid #000;
		padding: 2px 5px;
		border-collapse: collapse;
	}
	td:first-child {
		width: 110px;
	}
	td:last-child {
		text-align: right;
	}
	tr:last-child td {
		padding: 5px;
	}
}

.pr_receipt_footer {
	width: 100%;
	margin-top: 20px;
	td {
		padding: 0;
	}
	td:first-child {
		width: 200px;
	}
	tr td:last-child {
		text-align: right;
	}
	color: black !important;
	font-size: 12px;
}

h2.printed-text {
	margin: 0;
	color: #000;
}

.view-ticket {
	width: 280px;
	height: auto;
	float: left;
	display: none;
}

.admin-fees {
	overflow: hidden;
	width: 100%;
	span:first-of-type {
		margin-top: -14px;
	}
}

@media all {
	table,
	th,
	td {
		border-collapse: collapse;
		border-spacing: 0px;
	}
}

@media print {
	table,
	th,
	td {
		border-top: 0px !important;
	}

	body {
		-webkit-print-color-adjust: exact !important;
	}

	html,
	body {
		height: initial !important;
		overflow: initial !important;
	}
	.view-ticket {
		display: inline-block;
	}
	.ticket-title {
		color: #000000 !important;
	}
	.overflow-box {
		overflow: hidden;
		height: 397px;
		width: 100%;
	}
	.main-ticket-area {
		display: inline-block;
		width: 280px;
		height: 400px;
		margin: 0;
	}
	.ticket-card {
		background-image: none !important;
		border: solid 5px #000;
		position: relative;
		width: 100%;
		height: 350px !important;
		margin: 0 auto;
		border-radius: 60px;
	}
	.ticket-card:before {
		background-color: white;
		position: absolute;
		content: "";
		width: 80px;
		height: 80px;
		border: solid 5px #000;
		left: 47%;
		border-radius: 50%;
		top: -14%;
		border-top: 0;
		border-right: 0;
		z-index: 99999;
		transform: rotate(-45deg);
	}
	.ticket-card:after {
		position: absolute;
		content: "";
		background: #ffffff;
		width: 80px;
		height: 80px;
		border: solid 5px #000;
		left: 47%;
		border-radius: 50%;
		bottom: -14%;
		border-bottom: 0;
		border-left: 0;
		z-index: 99999;
		transform: rotate(-45deg);
	}
	.left-box {
		padding: 50px 60px 40px 60px;
		color: #333;
		flex: auto;
		max-width: 50%;
		width: 50%;
		/* float: left; */
		text-align: left !important;
	}
	.right-box {
		padding: 0;
		flex: auto;
		max-width: 50%;
		width: 50%;
		/* float: left; */
		text-align: center;
	}
	.passenger-type {
		font: Bold 16px/20px Lato;
		text-transform: uppercase;
		padding: 0;
		margin-left: 0 !important;
	}
	.left-box .origin {
		font: 400 16px/20px Lato;
		letter-spacing: 0.16px;
		margin: 25px 0 0 0;
	}
	.left-box .origin-name {
		font: 900 24px/32px Lato;
		letter-spacing: 0.24px;
		color: #333;
		border-bottom: 0;
		padding-bottom: 13px;
		max-width: 350px;
	}
	.left-box .destination.padding0.col {
		font: 400 16px/20px Lato;
		letter-spacing: 0.16px;
		margin: 5px 0 0 0;
	}
	.left-box .destination-name {
		font: 900 24px/32px Lato;
		letter-spacing: 0.24px;
		color: #333;
		border-bottom: 0;
		padding-bottom: 13px;
		max-width: 350px;
		margin-bottom: 5px;
	}
	.left-box .left-col-text {
		font: 700 12px/17px Lato;
		letter-spacing: -0.26px;
		margin: 7px 0 10px 0;
		max-width: 350px;
	}
	.barcode-img {
		text-align: center;
	}
	.barcode-img img {
		max-width: 210px;
		width: 210px;
		position: relative;
		left: 40px;
		margin-top: 5px;
	}
	.logo-img img {
		max-width: 220px;
		min-width: 220px;
		width: 220px;
		position: relative;
		left: 40px;
	}
	.count-quantity {
		margin-right: 10px;
		font-weight: 600;
	}
	.type-pass {
		font-weight: 400;
		margin-left: 5px;
		font-size: 16px;
	}
	.bottom-content-box {
		font: 400 14px Lato;
		letter-spacing: 0.11px;
		color: #2d3338;
		margin-top: 30px;
		padding: 0 15px;
		max-width: 250px;
		width: 250px;
		min-width: 250px;
		float: left;
	}
	.bottom-content-box img {
		/* float: left; */
		margin-right: 10px;
		webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
		filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
	}
	.bottom-content-box h4 {
		font: 900 18px Lato;
		letter-spacing: -0.48px;
		color: #4b4b4b;
	}
	.bottom-content-box a {
		font: 700 11px/16px Lato;
		letter-spacing: 0;
		color: #279cf7;
		margin-top: 4px;
		display: block;
	}
	.print-bottom-box {
		padding: 10px 50px;
		width: 80%;
		min-width: 80%;
		box-sizing: border-box;
		margin: 50px auto;
	}
	.terms-conditions {
		margin-top: 50px;
		border: 0;
		padding: 0;
	}
	.terms-conditions h3 {
		text-align: left;
		font: 700 16px/20px Lato;
		letter-spacing: 0;
		color: #2f2f35;
		margin: 0 0 10px;
		overflow: hidden;
	}
	.ticket-head {
		padding: 5px 80px;
		width: 100%;
		/* float: left; */
		margin-top: 10px;
	}
	.pull-left {
		/* float: left; */
	}
	.pull-right {
		/* float: right; */
	}
	.ticket-of {
		/* float: left; */
		margin-right: 20px;
		line-height: 36px;
	}
	.terms-conditions a.more-btn {
		font: 900 14px/18px Lato;
		letter-spacing: 0;
		color: #279cf7;
		margin-left: 2px;
		text-decoration: none;
	}
	.bottom-content-box a {
		font: 700 11px/16px Lato;
		letter-spacing: 0;
		color: #279cf7 !important;
		margin-top: 4px;
		display: block;
		text-decoration: none;
	}

	table.pr_receipt_footer:last-child {
		margin-bottom: -10px;
	}
}
//new css

* {
	box-sizing: border-box;
}

#print-wrapper {
	margin: auto;
	width: 200px;
	font-family: 'Verdana', sans-serif;
	color: #000;
	font-size: 8px !important;

	table {
		border-collapse: collapse;
		table-layout: auto;
		border: 0;
		width: 100%;
	}

	.ticket-header td {
		padding-bottom: 5px;
		vertical-align: top;
	}

	.truncate-txt {
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.bold {
		font-weight: 700;
	}
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.pb-print-5 {
	padding-bottom: 3px !important;
}

.pt-print-5 {
	padding-top: 3px !important;
}

.block {
	display: block;
}

@media all {
	.page-break {
		display: none;
	}
}

@media print {
	.page-break {
		display: block;
		page-break-before: always;
	}
}
