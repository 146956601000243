.route-wrapper {
  background-color: #0277cc;
  .route-container {
    padding-top: 33px;
  }
  .routebtn-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    word-break: break-all;
    @include media-breakpoint-only(xs) {
      justify-content: center;
    }
    @include media-breakpoint-only(sm) {
      justify-content: center;
    }
    @include media-breakpoint-only(md) {
      justify-content: start;
    }
    @include media-breakpoint-only(lg) {
      justify-content: start;
    }
    // @include media-breakpoint-only(xl) {
    //   justify-content: space-between;
    // }

    .routebtn {
      background-color: #ffffff;
      border: 1px solid #279cf7;
      color: #279cf7;
      padding: 7px 5px;
     // border-radius: 17.5px;
     border-radius: 8.5px;
      margin: 0 0 33px 0;
      width: 195px;
      font-family: "Lato-Bold";
      font-size: 14px;
      margin-right: 27px;
    }
    .routebtn[disabled] {
      background: #a8a8a8;
      color: #fff;
      border-color: #a8a8a8;
    }
  }
}

.bus-form-wrapper {
  background-color: #ebebeb;
  padding: 50px 0;
  .bus-form-container {
    background-color: #f2f3f4;
    padding-bottom: 20px;
    .header-bg {
      height: 88px;
      background-color: #0461a9;
    }
    .bus-label {
      font: 700 16px/22px Lato;
      color: #0077cc;
      &.grey {
        color: #666666;
        font: 700 20px/26px Lato;
      }
    }
    .bus-icon {
      margin-right: 8px;
    }

    /**
     * Form Grid
     */
    .bus-form-row {
      min-height: 68px;
      margin: 10px 0;
      align-items: center;
      .left {
        width: 195px;
        flex: initial;
        @include media-breakpoint-only(xs) {
          flex: 100%;
        }
        @include media-breakpoint-only(sm) {
          flex: 100%;
        }
      }
    }
  }

  /**
   * Fare zone Section
   */
  .fare-zone-wrapper {
    background-color: #ffffff;
    padding: 15px 15px 50px 15px;
    border: 1px solid #c4c4c4;
    text-align: center;
    h3 {
      font-family: "Lato-Bold";
      font-size: 16px;
      color: #666666;
      margin-bottom: 25px;
      margin-top: 25px;
    }
  }

  .schedule-btn {
    border-width: 1px !important;
  }
}

.route-display {
  display: flex;
  flex-direction: row;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 7px;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 7px;
}

.quick-seletion {
  color: white;
  margin-bottom: 13px;
}


