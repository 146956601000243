.main-header {
	width: 100%;
	background-image: url(../../../../assets/images/Header.png);
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 55px;
}

.agent-name {
	font-size: 14px;
	color: #001f30 !important;
	line-height: 26px;
	margin-top: 4px;
	margin-right: 1rem;
}
.agent-icon {
	margin-right: 1rem;
	img {
		height: 22px;
	}
}

.sidebar-icon {
	color: #666666 !important;
	cursor: pointer;
	font-size: x-large;
}

.sidenav {
	height: 100%;
	//position: relative;
	z-index: 1;
}

.sidenav-menu {
	height: 100%;
	overflow-y: auto;
	width: 280px;
	background: rgba(121, 121, 121, 0.73);
	border: 1px solid #ffffff;
	border-radius: 1px;
	position: absolute;
	right: 0;
	z-index: 1;
	//min-height: 890px;
}

.basket {
	width: 50px;
	height: 50px;
	border: 0;
	padding: 0;
	background: transparent;
	position: relative;
	margin-right: 2rem;
}

.basket .nav-link-count {
	position: absolute;
	background: #dd182a;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	font-size: 10px !important;
	color: #fff;
	line-height: 18px;
	font-weight: 700;
	text-align: center;
	right: -5px;
	top: 4px;
}

ul.sidenav-menu-links,
ul.sidenav-menu-links li ul {
	list-style-type: none;
	padding: 0;
}

.sidenav ul.sidenav-menu-links li a {
	color: #ffffff;
	letter-spacing: 0;
	line-height: 36px;
	padding: 10px 30px 10px 45px;
	text-decoration: none;
	display: block;
	border: none;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	background: #0461a9;
	border: 1px solid #ffffff;
	border-radius: 1px;
	cursor: pointer;
	position: relative;
}
.sidenav ul.sidenav-menu-links li a span:first-child {
	color: #ffffff;
	cursor: pointer;
	position: absolute;
	left: 13px;
	font-size: 24px;
	top: 8px;
}
.sidenav ul.sidenav-menu-links li .card-body {
	background: #1b4463;
	border: 1px solid #f9f9f9;
	font-family: Helvetica;
	font-size: 13px;
	color: #ffffff;
	letter-spacing: 0;
	text-align: justify;
}
.header-layer {
	background: #0060a9;
	min-height: 40px;
}

.main-logo {
	width: 285px;
}
.main-header nav.navbar {
	padding: 0;
	margin: 0;
	height: 75px;
}
.main-header ul.navbar-nav li.nav-item a.nav-link {
	padding: 28px 00px;
	font-size: 16px;
	color: #000000;
	line-height: 16px;
	font-weight: 700;
	margin-right: 25px;
}
.main-header ul.navbar-nav li.nav-item a.nav-link:hover {
	border-bottom: 2px solid #1a99f1;
}
.main-header ul.navbar-nav li.nav-item.active a.nav-link {
	border-bottom: 2px solid #1a99f1;
}
button.btn.profile-circle {
	box-shadow: none;
	outline: none;
}
.btn-group.open .dropdown-toggle.profile-circle {
	-webkit-box-shadow: none;
	box-shadow: none;
}
button.btn.profile-circle .profile-name {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #0461a9;
	font-size: 12px;
	color: #ffffff;
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
	margin-right: 10px;
	line-height: 35px;
}
button.btn.profile-circle:after {
	display: none;
}
.down-arrow {
	position: relative;
	margin-left: 5px;
}
.down-arrow::before,
.down-arrow::after {
	position: absolute;
	content: "";
	display: inline-block;
	background-color: #636f75;
	width: 2px;
	height: 8px;
	transform: rotate(45deg);
	right: 0;
	top: 0px;
}
.down-arrow::after {
	position: absolute;
	transform: rotate(-45deg);
	top: 0px;
	right: 5px;
}
.btn-group.header-dropdown.open .dropdown-menu {
	left: -80px;
	background: #ffffff;
	border: 1px solid #dce3eb;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	top: 50px;
}
.btn-group.header-dropdown.open a.dropdown-item {
	font-size: 14px;
	color: #303639;
	line-height: 17px;
	padding: 10px 20px;
}
.btn-group.header-dropdown.open a.dropdown-item:hover {
	background: #f4f5f7;
}
.navigation {
	display: flex;
	justify-content: space-between;
}

.btn-group.header-dropdown.open .dropdown-menu {
	top: 74px;
	right: 0;
}

.header-dropdown span.dropdown-item.span-as-anchor:hover {
	background: #0461a9;
	color: #fff;
}

/*responsive*/
@media only screen and (max-width: 991px) {
	.main-logo {
		width: 200px;
		padding: 20px 0;
	}
	.navigation .navbar-collapse.collapse.in {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		top: 70px;
		padding-bottom: 20px;
	}
	.navbar-collapse {
		display: none;
		flex-basis: auto;
		flex-grow: inherit;
		align-items: inherit;
	}
	.navigation ul.navbar-nav {
		padding-left: 25px;
		box-shadow: 0px 0px 15px #dcdcdc;
		background: #fff;
		margin-top: 0;
	}
	.navbar-toggler .icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		border-radius: 1px;
	}
	.navbar-toggler .icon-bar {
		background-color: #888;
	}
	.navbar-toggler .icon-bar + .icon-bar {
		margin-top: 4px;
	}
	.main-header nav.navbar {
		display: block;
		width: 100%;
		position: inherit;
	}
	.navigation button.navbar-toggler {
		top: 25px;
		position: relative;
		right: 0;
		float: right;
	}
	.btn-group.header-dropdown.open .dropdown-menu {
		top: 65px;
	}
}

#user-dropdown:after {
	display: none;
}
