strong {
	font-family: "Lato-Bold";
}
.journal-page-wrapper {
	margin: 0 40px;
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100%;
}
.journal-content-box {
	background-color: #ffffff;
	padding: 10px;
	-webkit-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.3);
	margin-bottom: 20px;
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.table-top-wrapper {
	padding: 0 15px;
	// margin-bottom: 60px;
	font-size: 16px;
	color: #001f30;
	label {
		margin: 0;
	}
	.input-wrapper {
		position: relative;
		margin: 10px 0 10px 10px;
		// flex: 1;
		svg {
			position: absolute;
			transform: rotate(90deg);
			top: 0;
			bottom: 0;
			right: 7px;
			margin: auto;
			cursor: text;
			color: #ddd;
		}
	}
}
.cell-border {
	border-bottom: 1px solid #dddddd;
}
.table-search-input {
	// border-bottom: 0 !important;
	font-size: 16px;
	border: 1px solid #dddddd !important;
	min-width: 300px;
}
// .form-control.table-search-input:focus {
//   // border: 0 !important;
// }
.table-search-input::placeholder {
	text-transform: uppercase;
	color: "#001F30";
	font-size: 16px;
}

.journal-modal {
	top: 10%;
	width: 340px;
	height: 485px;
	left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;
}

.journal-modal .modal-content,
.modal-header {
	border: 0;
	padding: 0;
}
.journal-modal .modal-header .close {
	padding: 0 1.5rem;
	margin: -1.5rem -1rem -1rem auto;
}

.journal-modal .modal-body {
	padding: 20px;
}
.journal-modal .modal-body img {
	margin: auto;
	justify-content: center;
	display: flex;
	margin-bottom: 15px;
}

.journal-modal .modal-body p {
	font-family: Helvetica;
	font-size: 24px;
	color: #001f30;
	letter-spacing: 0;
	text-align: center;
	line-height: 36px;
	margin: 0;
}

.journal-modal .modal-body button {
	border: 1px solid #279cf7;
	border-radius: 25px;
	height: 40px;
	width: 100%;
	margin-right: 26px;
	background: #279cf7;
	color: #fff;
	display: block;
	text-transform: uppercase;
}
.journal-modal .modal-body .logout-cancel {
	font-size: 16px;
	color: #0077cc;
	bottom: -30px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
}
.journal-row-highlighter {
	color: #a91a00;
	.text-highlighter {
		color: #a91a00;
	}
	span.expanded {
		border-bottom: 5px solid #a91a00;
	}
}
.text-highlighter {
	color: #0060a9;
}
.custom-table .table .tbody {
	overflow-y: visible;
	overflow-x: visible;
}
.row-depth-1 {
	.td {
		background: #e5eff6;
		&:first-child {
			background: #fff;
		}
	}
}
// .row-depth-1 {
//   background: #e5eff6;
// }
// .row-depth-1 .td:first-child {
//   background: #fff;
// }

.journal-area .btn-area button {
	border: 1px solid #0661a9;
	border-radius: 10px;
	height: 44px;
	width: 152px;
	margin-right: 26px;
	margin-top: 0px;
	color: #fff;
	font-size: 16px;
	margin-left: 25px;
	background-color: #0661a9 !important;
}

button.btn:disabled {
	background-color: #a8a8a8 !important;
	border-color: #a8a8a8;
}

.journal-modal.hight-auto {
	height: auto;
}
.out-from-scrollbar {
	padding-right: 38px !important;
}
.out-from-scrollbar.admin-fee {
	width: 230px !important;
	table {
		width: 100%;
		td {
			border-top: 0;
			padding: 1px 2px !important;
		}
		td:first-child {
			text-align: right;
		}
	}
}
