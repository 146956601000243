.custom-table {
  // padding: 1rem;
  display: flex;
  overflow: auto;
  position: relative;
  &.no-data-in-table {
    overflow: hidden;
  }
  // Checkbox style
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    // margin: 5px;
    cursor: pointer;
  }
  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: inherit;
    bottom: initial;
    margin: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
    .spinner-border {
      position: inherit;
      left: inherit;
      top: inherit;
    }
  }

  // checkbox custom design
  .checkbox-custom-label {
    position: relative;
    cursor: pointer;
  }
  .checkbox-custom + .checkbox-custom-label:before {
    content: "";
    background: #fff;
    border: 1px solid #279cf7;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }

  .checkbox-custom:checked + .checkbox-custom-label:before {
    background: #279cf7;
  }
  .checkbox-custom[disabled] + .checkbox-custom-label:before {
    background: #eae9e9;
    border: 1px solid #999a9a;
  }
  // Checkbox design as radio button

  .checkbox-as-radio + .checkbox-custom-label:before {
    content: "";
    background: transparent;
    border: 1px solid #000000;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }
  .checkbox-as-radio:checked + .checkbox-custom-label:before {
    background: #000000;
    box-shadow: inset 0px 0px 0px 3px #fff;
  }

  .table {
    border-spacing: 0;
    display: flex;
    flex-direction: column;
    // border: 1px solid black;
    .no-data-found {
      position: absolute;
      width: 100%;
    }
    .thead {
      // overflow-y: auto;
      // overflow-x: hidden;
      .tr {
        color: #0060a9;
        font-family: "Lato-Bold";
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        .th:first-child {
          border-bottom: 0;
        }
        .th {
          border-bottom: 1px solid #dddddd;
        }
      }
    }

    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 500px;
      min-height: 100px;
      // position: relative;
      .tr {
        margin-bottom: 4px;
      }
    }

    .tr {
      font-size: 16px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    // td
    .th,
    .td {
      margin: 0;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      position: relative;

      :last-child {
        border-right: 0;
      }
    }
  }
  span.can-extended {
    cursor: pointer;
  }
  span.expanded {
    border-bottom: 5px solid #0461a9;
  }
  .sort-container {
    display: flex;
    height: 15px;
    width: 24px;
    flex-direction: column;
    justify-content: space-between;
  }
  .text-highlighter {
    color: #0060a9;
  }
}
.app-pagination {
  display: flex;
  justify-content: flex-end;
  .page-item.disabled .page-link {
    border: 0;
  }
  .page-item {
    max-width: initial;
    width: initial;
    border-radius: 0;
    height: auto;
    &.disabled {
      .page-link {
        background-color: #6d7278;
        color: #fff;
      }
    }
    &.active {
      .page-link {
        padding: 7px 15px;
        background-color: #ffffff;
        color: #6d7278;
      }
    }
    .page-link {
      background-color: #6d7278;
      border: 0;
      padding: 7px 10px;
      width: auto;
      color: #fff;
      line-height: initial;
      border-radius: 10px;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  // .page-item.disabled .page-link {}
}
.per-page-selector {
  border: 0;
  border-bottom: 1px solid #000;
  padding: 5px 0;
  &:focus {
    outline: 0;
  }
}
.child-text-truncate span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.busy-area {
  position: absolute;
  height: 100%;
  background: rgba(0,0,0, 0.2);
  z-index: 99;
}