.ticket-pagination .pagination {
  text-align: center;
  display: inline !important;
}
.ticket-pagination {
  height: 25px;
  width: 160px;
  margin: 0 auto;
}
.pagination {
  border-radius: 0;
  margin: 0 4px;
  font: 400 10px/6px Lato;
  letter-spacing: 0;
  color: #0094D6;
  padding: 7px 16px;
  margin-top: -3px;
  outline: none;
  box-shadow: none;
}
.pagination:hover,
.pagination:focus,
.pagination:active {
  background: transparent;
  outline: none;
  box-shadow: none !important;
  color: #0094D6;
}
.pagination .active a {
  z-index:0 !important;
}
ul.pagination li a.page-link {
  padding: 8px 8px;
  line-height: 6px;
  font-size: 16px;
  letter-spacing: 0;
  color: #279CF7;
  margin: 0;
  border: none !important;
  &:focus {
    box-shadow: none;
  }
}
ul.pagination li:focus {
  color: #FFFFFF;
  background: #279CF7;
  outline: none;
  box-shadow: none;
}
ul.pagination li.active a.page-link {
  color: #FFFFFF;
  background: #279CF7;
}
