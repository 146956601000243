.checkout-page-title h2 {
	font-size: 28px;
	font-weight: 400;
	color: #4c6070;
	margin: 0;
}
.checkout-page-title p {
	font-size: 16px;
	font-weight: 700;
	color: #001f30;
	margin-bottom: 5px;
}
.checkout-page-row {
	width: 100%;
}
.checkout-page-row .checkout-text {
	margin-bottom: 0;
	margin-top: 16px;
}

a.learn-more-txt {
	font-size: 15px;
	font-weight: bold;
	color: #279cf7;
	text-transform: none;
}

.order-summary,
.details,
.amount,
.button,
.textdata {
	margin-left: 15px;
	margin-top: 15px;
	margin-right: 15px;
}

.error-field {
	border-color: #f0270c !important;
}
a.view-cart-icon {
	position: relative;
	font-weight: 700;
	padding-right: 15px;
}
.logIn-Btn {
	text-decoration: none;
	cursor: pointer;
	color: #279cf7;
}
.checkout-page-msg {
	background: #e5eff6;
	padding: 10px 15px;
	margin-top: 0px;
	margin-bottom: 20px;
	margin-right: 0;
	margin-left: 0;
}
.checkout-page-msg p {
	margin: 0;
	font-size: 14px;
	color: #000;
	line-height: 18px;
}

.checkout-btn-group {
	text-align: right;
	margin-top: 50px;
	button.btn {
		width: 186px;
		border: 1px solid !important;
		margin: 0 0 10px;
		height: 55px;
		border-radius: 40px;
	}
}
.order-summary-box button.btn {
	border: 1px solid !important;
}
.checkout-page-row .order-summary-box {
	margin: 60px 0 0 0;
	border: solid 1px #d0dbe3;
	border-radius: 9px;
	min-height: 400px;
}
.checkout-page-row .order-summary-mobile button.btn {
	width: 100% !important;
}
.checkout-page-row .order-summary-mobile button.btn.btn-save {
	background: #279cf7;
	border: 1px solid #279cf7 !important;
	width: auto !important;
	font-size: 14px;
	color: #ffffff;
	text-align: center;
	line-height: 20px;
	height: 34px;
	padding: 0 30px;
	margin-bottom: 0;
	min-height: 34px;
	min-width: auto;
	font-weight: 400;
}
.order-summary-mobile .cart-mail-box {
	margin: 10px 15px 20px 15px;
}
.cart-mail-box button.btn-edit {
	color: #279cf7;
	background: transparent;
	border: 0;
}
input.form-control.input-ragular {
	padding: 24px 10px !important;
}
.checkout-form-field {
	.invalid-feedback,
	.error-msg {
		background: #ffe6e6;
		padding: 5px 12px 8px !important;
		font-size: 14px;
		color: #bf1111;
		line-height: 20px;
		margin-top: 0;
		&:before {
			display: none;
		}
	}
}

.checkout-page-row {
	.errorField {
		border-color: #bf1111;
	}
}

.splitCreditCard h4 {
	line-height: 49px !important;
}

.splitCreditCard {
	background: #e5eff6 !important;
	padding-left: 10px;
	margin-bottom: 10px;
	padding-right: 10px;
}
.cardFalidBox {
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding: 15px;
	margin: 20px 0px 20px 0px;
}

.edit-billing {
	color: rgb(39, 156, 247);
	position: absolute;
	right: 29px;
	cursor: pointer;
}

a.view-cart-icon span {
	font-weight: 500;
	font-size: 30px;
	position: absolute;
	top: -2px;
	right: -2px;
}

.checkout .form-group input.is-invalid {
	padding: 12px 0px 6px 10px !important;
}

.checkout .float-label-control input.form-control,
.float-label-control select.form-control,
.float-label-control textarea.form-control {
	padding: 6px 8px !important;
}

.checkout input.form-control:focus {
	padding: 23px 0 5px 9px !important;
}
.checkout input.form-control:not([value=""]) {
	padding: 20px 0 5px 9px !important;
}

// .checkout-page-row .button.btn-secondary,
// button.btn-secondary:hover,
// button.btn-secondary:active {
// 	width: 202px;
// 	height: 44px;
// 	border-radius: 25px;
// 	font-size: 16px;
// 	font-weight: bold;
// 	margin: 0px auto;
// 	margin-bottom: 20px;
// 	background: #ffffff;
// 	border: 1px solid #0077cc;
// 	border-radius: 25px;
// }

// .checkout-page-row .checkout-form-field .btn-secondary {
// 	width: 186px;
// 	border: solid 1px !important;
// 	margin: 10px;
// }

// .checkout-page-row .checkout-page-row .btn-secondary {
// 	width: 186px;
// 	border: solid 1px !important;
// 	margin: 10px;
// }

// .checkout-page-row .btn-secondary {
// 	width: 202px;
// 	height: 44px;
// 	border-radius: 25px;
// 	font-size: 16px;
// 	font-weight: bold;
// 	margin: 0px auto;
// 	margin-bottom: 20px;
// 	background: #ffffff;
// 	border: 1px solid #0077cc !important;
// 	border-radius: 25px;
// }
.modal-open .modal {
	overflow-y: hidden !important;
}

.checkout-page-row .btnCheckout {
	width: 174px;
}

.checkout-page-row .button.btnCheckout,
button.btnCheckout:hover,
button.btnCheckout:active,
button.btnCheckout:focus {
	border: 2px solid #a8a8a8 !important;
	background-color: #a8a8a8;
}

@media (max-width: 991px) {
	.orbit-neg-28-mar-rt {
		margin-right: -30px !important;
	}
	.checkout-page-row .form-group {
		width: 100% !important;
	}
	.checkout-page-row {
		width: auto;
	}
	.checkout-page-row button.btn {
		width: 100% !important;
		font-weight: 400;
		border: 1px solid !important;
		margin: 10px 0;
	}
	.checkout-form-field {
		padding: 0 15px;
	}
	.checkout-page-row .orbit-neg-28-mar-rt {
		margin-right: 0 !important;
	}
	.order-card.order-summary-box.card {
		margin-right: 0 !important;
	}

	.order-summary-mobile .details {
		padding: 15px 0;
		margin: 0 30px;
		border-bottom: solid 1px #c8c8c9;
	}
	.order-summary-mobile .order-summary {
		padding-bottom: 5px !important;
	}
	.order-summary-mobile .order-listing {
		margin: 7px 0 0;
	}
	.order-summary-mobile .order-summary-box {
		border-radius: 15px;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.order-summary-mobile .textdata {
		margin-top: 0;
	}
	.order-summary-mobile .amount.card-footer {
		margin: 15px 30px 8px 30px;
	}
	.order-summary-mobile .cart-mail-box {
		margin: 10px 15px 0 15px;
	}
	.checkout-page-row h2 {
		font-size: 24px;
		color: #001f30;
		text-transform: capitalize;
		line-height: 65px;
	}
	.checkout-page-msg {
		margin-top: -20px;
		margin-bottom: 30px;
		margin-right: -15px;
		margin-left: -15px;
	}
	.splitCreditCard h4 {
		font-size: 1rem;
		line-height: 34px !important;
	}

	.checkout-page-row .cart-mail-box .error-msg {
		left: auto;
		margin-left: unset;
		max-width: 233px;
	}
}

.checkout span {
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	color: #001f30;
	line-height: 26px;
	font-weight: 700;
}

.checkout input {
	background: #ffffff !important;
	border: 1px solid #ced4da !important;
	border-radius: 4px !important;
	border-radius: 4px !important;
	padding: 10px 8px !important;
}
.checkout input::placeholder {
	font-size: 16px !important;
	color: #8d8d8d !important;
	text-align: left !important;
	line-height: 20px !important;
}

.checkout .col-md-12 {
	margin-bottom: 30px;
}

.checkout .col-md-4 {
	margin-bottom: 30px;
}

.checkout .col-md-6 {
	margin-bottom: 30px;
}

.checkout-form-field .btnCheckout:disabled {
	background: #a8a8a8;
}

.checkout-page .container {
	max-width: 1240px;
}

.checkout-page .amount.card-footer {
	margin-bottom: 0;
	margin-top: 7px;
}

.checkout-page .order-summary-box .details {
	margin-bottom: 10px;
}

.breaker {
	border: 1px solid #c8c8c9;
}

// .shopping-cart-admin-free {
// 	li {
// 		margin-bottom: 15px;
// 		vertical-align: top;
// 		min-width: 39px;
// 		.admin_fee_label,
// 		.admin_fee_price {
// 			color: #001f30;
// 			font-size: 12px;
// 		}
// 		.admin_fee_price {
// 			margin-bottom: 3px;
// 		}
// 	}
// }

//   .ticketdetails {
// 	display: flex;
// 	background: #F2F3F4;
// 	flex-direction: row;
// 	font: 700 14px/20px Lato;
// 	padding: 5px;
// 	.quanity {
// 		margin-right: 14px;
// 	}
// 	.ticket {
// 		margin-right: 70px;
// 	}
// 	.passenger {
// 		margin-right: 60px;
// 	}
// }

.checkout-modal {
	top: 20%;
	width: 400px;
	height: auto;
	left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;
	text-align: center;

	.modal-content,
	.modal-header {
		border: 0;
		padding: 0;
	}
	.modal-header .close {
		padding: 0.2rem;
		margin: -2rem 0.5rem -1rem auto;
		font-size: 38px;
	}

	.modal-body {
		padding: 15px;

		img {
			margin: auto;
			justify-content: center;
			display: flex;
			margin-bottom: 15px;
		}

		h3 {
			font: 700 24px/32px Lato;
			color: #001f30;
		}
		span {
			word-break: break-word;
			display: block;
			font-size: 20px;
			margin-bottom: 10px;
			background-color: #e9f5fe;
			color: #279cf7;
			font-weight: 700;
			padding: 8px;
		}

		.message {
			font-size: 18px;
			color: #001f30;
			margin: 0 35px;
		}

		button {
			width: 100%;
			max-width: 280px;
			margin-top: 24px;
		}
	}
}

.checkout span.emailed_text {
	font-size: 16px;
	font-weight: 400;
	span {
		display: block;
		font-size: 16px;
		font-weight: 400;
	}
	b {
		font-size: 20px;
		font-weight: bold;
	}
	.btn {
		line-height: normal;
		margin-top: -5px;
		padding-left: 5px;
		font-weight: 400;
		background: none;
		color: #0461a9;
		border: 0;
		&:focus {
			box-shadow: none;
		}
	}
	.btn-link.btn-primary:not(:disabled):not(.disabled):active {
		padding-left: 5px;
	}
}
.checkout_Final {
	color: #001f30;
	text-align: center;
	width: 610px;
	margin: 0 auto;
	border-radius: 1rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	.card-body {
		padding: 50px 35px;
	}
	h3 {
		font-size: 24px;
		font-weight: bold;
	}
	div.message {
		font-size: 18px;
	}
	.btn {
		width: 50%;
		text-transform: uppercase;
	}
}

.emailboxpostition {
	position: relative;
	label.form-control-placeholder {
		margin: 8px 0px 8px -5px !important;
	}
}
