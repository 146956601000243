.bold {
	font-weight: 700;
}
.semi_bold {
	font-weight: 600;
}

// Custom scrollbar Start
div::-webkit-scrollbar {
	width: 3px;
	background: #e7e7e7;
}
div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
div::-webkit-scrollbar-thumb {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background: #d8d8d8;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
div::-webkit-scrollbar-thumb:window-inactive {
	background: #d8d8d8;
}
// Custom scrollbar End

h2 {
	font-size: 30px;
	font-weight: 400;
	color: #0060a8;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.spinner-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.spinner-bg:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: rgba(245, 245, 245, 0.5);
	top: 0;
}

.error-field {
	border-color: #f0270c !important;
}

.error-msg {
	color: #ff4500;
	margin-bottom: 10px;
	margin-top: 5px;
	padding-left: 30px !important;
	position: relative;
	overflow: hidden;
	min-height: 30px;
	display: flex;
	align-items: center;
}
.error-msg:before {
	position: absolute;
	content: "!";
	width: 20px !important;
	height: 20px !important;
	background: #ff4500;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-size: 16px !important;
	font-weight: bold;
	left: 0;
	line-height: 20px !important;
}

.origin-label,
.destination-label {
	font-size: 16px;
	color: #0060a8;
	font-weight: bold;
	text-transform: uppercase;
	padding: -2px 0 0 0;
}

.origin-label,
.destination-label {
	display: inline;
}

.fares-destination {
	display: flex;
	float: right;
}
.fare-origin {
	display: flex;
}

.section-padding {
	padding: 30px 0;
	width: 100%;
}

button.btn,
.btn-primary {
	// width: 260px;
	height: 50px;
	border-radius: 25px;
	font-size: 16px;
	font-weight: bold;
	border: 2px solid #279cf7;
	background-color: #279cf7;
	color: #ffffff;
	// margin: 8px auto;
	// -webkit-tap-highlight-color: transparent !important;
}

.btn.w260 {
	width: 260px;
}

.secondarybutton {
	width: 260px;
	height: 50px;
	background-color: #ffffff !important;
	color: #279cf7 !important;
	border: 2px solid #279cf7 !important;
	margin: 8px auto;
	-webkit-tap-highlight-color: transparent !important;
}

button.btn-secondary,
button.btn-secondary:focus,
button.btn-secondary:hover,
button.btn-secondary:active {
	background-color: #ffffff !important;
	color: #279cf7 !important;
	border: 2px solid #279cf7 !important;
	-webkit-tap-highlight-color: transparent !important;
}
button.btn-primary:disabled,
button.btn-primary:disabled:hover {
	background-color: #a8a8a8 !important;
	color: #ffffff !important;
	border: 2px solid #a8a8a8 !important;
}
.m-initial {
	margin: initial !important;
}

.float-label-control {
	margin-bottom: 1em;
	width: 100%;
}
.float-label-control label,
.float-label-control input,
.float-label-control textarea {
	transition: all 0.2s;
	touch-action: manipulation;
}

.float-label-control input.form-control,
.float-label-control select.form-control,
.float-label-control textarea.form-control {
	background: #ffffff;
	border: 1px solid #ced4da;
	border-radius: 4px;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 52px;
	font-size: 16px;
	font-weight: 400;
	padding: 12px 0px 6px 12px !important;
}

.float-label-control input:placeholder-shown + label,
.float-label-control textarea:placeholder-shown + label {
	max-width: 86.66%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transform-origin: left bottom;
	transform: translate(0, 3.3rem) scale(1);
	color: #000;
	font-weight: normal;
	font-size: 16px;
	margin: 0;
	opacity: 0;
}

.float-label-control
	input:not(:placeholder-shown)
	+ label.form-control-placeholder,
.float-label-control
	textarea:not(:placeholder-shown)
	+ label.form-control-placeholder,
.float-label-control input:focus + label.form-control-placeholder,
.float-label-control textarea:focus + label.form-control-placeholder {
	transform: translate(0, -0.5rem) scale(1);
	color: #1982c6 !important;
	font-size: 16px;
	font-weight: 400;
	opacity: 1;
	margin: 8px 0px 8px 12px;
}
.float-label-control input.form-control:focus,
.float-label-control select.form-control:focus,
.float-label-control textarea.form-control:focus {
	border-color: #777;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #555;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.float-label-control
	input:not(:placeholder-shown)
	+ label.form-control-placeholder,
.float-label-control
	textarea:not(:placeholder-shown)
	+ label.form-control-placeholder,
.float-label-control input:focus + label.form-control-placeholder,
.float-label-control textarea:focus + label.form-control-placeholder {
	transform: translate(0, -0.5rem) scale(1);
	color: #1982c6 !important;
	font-size: 16px;
	font-weight: 400;
	opacity: 1;
	margin: 8px 0px 8px 10px;
}

.cursor-pointer {
	cursor: pointer;
	font-size: 15px;
	font-weight: bold;
	color: #279cf7;
	text-transform: none;
}

.form-control-placeholder.form-label {
	margin: 0;
	position: absolute;
	top: 3px;
	left: 17px;
	font-size: 12px !important;
}

.printing-logo {
	float: left;
	width: 33%;
	margin-top: 30px;
	display: none;
}

// .print-all-tickets {
// 	/* display: none */;
// }

.origin-label,
.destination-label {
	font-size: 16px;
	color: #0060a8;
	font-weight: bold;
	text-transform: uppercase;
	padding: -2px 0 0 0;
}

.mobile-pagination,
.mobile-ticket-number,
.mobile-arrow {
	display: none;
}
.print-icon-left {
	float: left;
	margin: 0 15px 5px 0;
}
.print-all-ticket-label {
	font-weight: 700;
	font-size: 16px;
	letter-spacing: -0.31px;
	color: #0077cc;
	margin-right: 35px;
}
.print-icon-right {
	margin: 0 15px 5px 0;
}
.ticket-pagination {
	display: inline;
}

.the-pagination {
	width: 300px;
	height: 40px;
	text-align: center;
	margin: 16px auto;
}
.prev-button {
	margin: -3px 8px 0 0;
}

.div-pagination {
	width: 155px;
	display: inline-block;
	margin-left: 25px;
	box-sizing: unset;
}
.pagination-ul {
	display: inline !important;
	margin: 0 auto !important;
	border: none !important;
	overflow: auto !important;
}
.round-numbers {
	border-radius: 15px !important;
}
.desktop-pagination {
	display: inline;
	cursor: pointer;
	color: #279cf7;
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

.coach-header {
	height: 75px;
}

ul.nav.navbar-nav li a:hover {
	color: #ffffff !important;
}

.page-item {
	max-width: 34px;
	float: left;
	padding: 0;
	text-align: center;
}

.div-pagination {
	width: 155px;
	display: inline-block;
	margin-left: 25px;
	box-sizing: unset;
}

.go-right {
	float: right;
	display: inline-block;
}

.ticket-pagination {
	height: 25px;
	width: 160px;
	margin: 0 auto;
}

@media print {
	.printing-logo {
		display: inline-block;
		float: none !important;
	}
	.print-all-tickets,
	.origin-label-ticket,
	.destination-label-ticket,
	.passenger-type,
	.origin,
	.destination {
		display: block !important;
	}
	.origin-name,
	.destination-name {
		border-bottom: 1px solid #222222 !important;
	}
	.passenger-type {
		margin-left: 16px !important;
	}
	.ticket-title {
		color: #2e2e2e !important;
	}
	.ticket-expiration {
		float: right !important;
		display: inline-block !important;
		padding-top: 30px !important;
		font-size: 14px;
	}
	.tickets-white-top,
	.tickets-white-bottom {
		display: inline;
	}
	.section-padding,
	.col-md-12,
	.col-md-6,
	.main-ticket-area {
		float: none !important;
	}
	.barcode-img,
	.logo-img {
		padding: 0;
		margin: 0 auto;
	}
	.logo-img img {
		margin: -8px 0 0 0 !important;
	}
	.barcode-img img {
		margin: -16px 0 0 0 !important;
	}
	.barcode-text {
		font-size: 14px !important;
		margin: 4px 0 0 60px !important;
	}
	#div_coach_account,
	.main-header,
	.no-print,
	.profile-area,
	.btn,
	.btn-primary,
	.btn-secondary,
	.expand-icon,
	.ticket-text,
	.passenger-type-mobile,
	.ticket-expiration-mobile,
	ticket-valid-mobile,
	nav,
	footer {
		display: none !important;
	}
}

// Fixed height layout style

.fixed-layout {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	.sidenav {
		height: 0;
	}
	.base-height {
		min-height: inherit;
	}
	.section-padding {
		overflow: hidden;
		flex: 1;
	}
}

.launch-select {
	width: 470px;
	margin: 0px auto;
	height: 221px;
	background: #ffffff;
	border-radius: 15px;
}

.select-text {
	font-size: 24px;
	padding: 30px 0 8px 46px;
	font-weight: 700;
}

.select-dropdown {
	font-size: 16px;
	font-weight: 700;
	padding: 0px 46px 8px 46px;
	height: 60px;
	width: 100% !important;
}
.center {
	margin: 0;
	position: absolute;
	top: 45%;
	-ms-transform: translateY(-45%);
	transform: translateY(-45%);
	left: 40%;
}

.postiontheme {
	position: relative;
	overflow: hidden;
}

.fontweight {
	font-weight: normal !important;
}

.receipt-block {
	width: 100%;
	height: 13px;
	display: block;
}

.receipt-font {
	height: 10px;
	float: left;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 8px;
}

.ticketCount-box {
	width: 56px !important;
	margin-right: 0 !important;
	font-size: 16px;
	font-weight: 400;
	vertical-align: middle;
	padding: 0.375rem 0.75rem !important;
	border: 1px solid #ced4da !important;
	border-radius: 0.25rem !important;
	color: #001f30;
	font: 400 16px/20px Lato;
	text-align: center;
	margin: 0 10px !important;
}

.pointer-none {
	pointer-events: none;
}

//Header
@import "Header/Header.scss";

//Loader
@import "Loader/Loader.scss";

//Footer
@import "Footer/Footer.scss";

// Login
@import "Login/Login.scss";

//Busfares
@import "BusFares/BusFares.scss";

//ShoppingCart
@import "ShoppingCart/ShoppingCart.scss";

//CashOut
@import "CashOut/CashOut.scss";

//Checkout
@import "Checkout/Checkout.scss";

//Tickets
@import "Tickets/Tickets.scss";

//Pagination
@import "Tickets/Pagination/Pagination.scss";

//TicketContainer
@import "Tickets/TicketContainer/TicketContainer.scss";

//PrintTickets
@import "Tickets/PrintTickets/PrintTicket.scss";

//Dashboard
@import "Dashboard/dashboard.scss";

// Radio
@import "./radio.scss";

@import "./Journal/journal.scss";

//Paid In
@import "./PaidIn/PaidIn.scss";
