.paidin-wrapper {
	max-width: 780px;
	margin-top: 37px;

	h3.heading {
		font: 700 30px/26px Lato;
		color: #0060a9;
		text-transform: uppercase;
		margin-bottom: 34px;
	}
	.nav-pills {
		margin-bottom: 34px;
		.nav-item {
			background: #ffffff;
			border: 1px solid #dee5ee;
			.nav-link {
				border-radius: 0;
				text-align: initial;
				font: 700 14px/25px Lato;
				color: #0060a9;
				&.active {
					background: #0060a9;
					color: #ffffff;
				}
			}
		}
		.first-item {
			border-radius: 8px 0 0 0;
			.active {
				border-radius: 8px 0 0 0;
			}
		}
		.last-item {
			border-radius: 0 8px 0 0;
			.active {
				border-radius: 0 8px 0 0;
			}
		}
	}
	h1.tab-heading {
		min-height: 46px;
		background: #e5eff6;
		font: 500 24px/26px Lato;
		color: #001f30;
		padding: 9px 0 11px 17px;
		margin-bottom: 26px;
	}

	.float-label-control {
		.form-control {
			border: 1px solid #ced4da !important;
			border-radius: 0.25rem !important;
			padding: 16px 0 16px 14px !important;
			height: 52px;
			max-height: 100%;

			&:disabled {
				background-color: #edeff0;
			}
		}
		.css-yk16xz-control,
		.css-1pahdxg-control {
			min-height: 52px;
		}
		.form-control:focus,
		.form-control:not([value=""]) {
			padding: 22px 0 10px 10px !important;
		}
	}

	.envelopesNo {
		.css-2b097c-container {
			.css-26l3qy-menu {
				left: auto;
				right: 0;
				padding: 5px;
				overflow: hidden;
				width: 100px;
				.css-4ljt47-MenuList {
					max-height: 180px;
					padding-bottom: 0px;
					padding-top: 0px;
					div {
						border-bottom: 1px solid #e2e2e2;
						&:last-child {
							border-bottom: 0px solid #e2e2e2;
						}
					}
					.css-9gakcf-option {
						background-color: #fff;
						color: #279cf7;
						&:after {
							content: '';
							display: inline-block;
							transform: rotate(45deg);
							height: 16px;
							width: 7px;
							border-bottom: 1px solid #279cf7;
							border-right: 1px solid #279cf7;
							float: right;
						}
					}
				}
			}
		}
	}

	.calendar-icon {
		position: absolute;
		top: 15px;
		right: 12px;
		cursor: pointer;
	}
	.col-md-4 {
		.float-label-control {
			max-width: 200px;
			position: relative;
			.form-control-placeholder {
				left: 2px;
			}
		}
		.react-datepicker-wrapper {
			display: block;
		}

		.css-2b097c-container + .form-control-placeholder {
			left: 12px;
			color: #1982c6;
		}

		.css-1uccc91-singleValue {
			color: #495057;
			top: 70%;
			font-size: 16px;
		}
	}

	.react-datepicker__input-container {
		.form-control-placeholder.form-label {
			left: 3px;
		}
	}

	.react-datepicker__triangle {
		left: auto !important;
		right: 46px;
	}

	.btn-grp {
		button {
			width: 170px;
		}
	}
	.react-datepicker__close-icon {
		right: 32px;
	}
	::placeholder {
		font: 500 16px/20px Lato !important;
		color: #8d8d8d !important;
	}
}

.paidin-modal {
	top: 25%;
	width: 330px;
	height: 349px;
	left: 40%;
	background: #ffffff;
	border: 1px solid #979797;
	border-radius: 8px;
	padding-right: 0 !important;

	.modal-content,
	.modal-header {
		border: 0;
		padding: 0;
	}
	.modal-header .close {
		padding: 0 1.5rem;
		margin: -1.5rem -1rem -1rem auto;
	}

	.modal-body {
		padding: 20px;

		img {
			margin: auto;
			justify-content: center;
			display: flex;
			margin-bottom: 15px;
		}

		h3 {
			font-size: 24px;
			color: #001f30;
			letter-spacing: 0;
			text-align: center;
			font-weight: bold;
		}

		.message {
			font-size: 18px;
			color: #001f30;
			letter-spacing: 0;
			text-align: center;
		}

		button {
			border: 1px solid #279cf7;
			border-radius: 25px;
			height: 50px;
			width: 100%;
			margin-right: 26px;
			background: #279cf7;
			color: #fff;
			display: block;
			text-transform: uppercase;
			margin-top: 24px;
		}
	}
}

.paidinputwidth {
	// margin-right: 57px !important;
}

.agentreceiptpaid,
.receiptpaidIn {
	text-align: right;
	color: #000;
}
.agentreceiptpaid {
	font-weight: bold;
}

.driver-print-cont {
	// width: 290px;
	.ticket_header {
		width: 100%;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	.tr-8header {
		.ticket_header:nth-child(2n + 1) {
			margin-top: 0.3cm;
		}
	}
	.paid_receipt_total {
		margin-bottom: 15px;
		margin-top: 15px;
		td {
			border-bottom: 1px solid #000;
			padding: 2px 5px;
			border-collapse: collapse;
		}
		// tr.bold:nth-child(8) td {
		// 	border-bottom: 1px solid #fff;
		// }
		th:last-child {
			text-align: right;
		}
		th:nth-child(3) {
			text-align: center;
		}
		.ticket_header td,
		.border-b-0 {
			border-bottom: 0;
		}
		td[colspan="4"]:first-child {
			width: 100%;
		}
	}
	.paid_receipt_total.tr-8 {
		tr.bold:nth-child(8) td {
			border-bottom: 1px solid #fff;
		}
	}
}

@media (max-width: 991px) {
	.paidin-wrapper {
		.col-md-4 {
			.float-right {
				float: none !important;
			}
			.float-label-control,
			.react-datepicker-wrapper {
				max-width: 100%;
				display: block;
			}
		}
		.react-datepicker-wrapper {
			max-width: 100%;
			display: block;
		}
	}
}
@page {
	border-bottom: 0;
}
@media print {
	html,
	body {
		padding: 0;
		margin: 0;
	}
	.driver-print-cont {
		.pr_receipt_total {
			.ticket_header {
				margin-top: 20px;
			}
			.ticket_header td,
			.border-b-0 {
				border-bottom: 0;
			}
		}
		.paid_receipt_bottom {
			padding-top: 3.5cm;
		}
		.paidIn_receipt_footer {
			width: 100%;
			margin-top: -6px;
			td {
				padding: 0;
			}
			td:first-child {
				width: 200px;
			}
			tr td:last-child {
				text-align: right;
			}
			color: black !important;
			font-size: 12px;
		}
	}
}
