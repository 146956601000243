.view-ticket {
	width: 220px;
	height: 100%;
	// height: 1000px;
	overflow: hidden;
	margin: 8px auto;
}
.view-ticket h3 {
	text-align: center;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	font-size: 24px;
	color: #4c6070;
	margin: 0;
}
.view-ticket h4 {
	text-align: center;
	font: 400 18px Lato;
	letter-spacing: -0.31px;
	color: #4c6070;
	margin: 0;
}
.ticket-card {
	background: no-repeat;
	background-size: 100%;
	padding: 0 15px;
	width: 100%;
	height: 800px;
}

.view-ticket .passenger-type {
	font: Bold 16px/20px Lato;
	text-transform: uppercase;
}
.left-box .left-col-text {
	font: 700 14px/17px Lato;
	letter-spacing: -0.26px;
	margin: 7px 0 10px 0;
	max-width: 91%;
}
.bottom-content-box img {
	float: left;
	margin-right: 10px;
}
.bottom-content-box h4 {
	font: 900 18px Lato;
	letter-spacing: -0.48px;
	color: #4b4b4b;
}
.bottom-content-box a {
	font: 700 11px/16px Lato;
	letter-spacing: 0;
	color: #279cf7;
	margin-top: 4px;
	display: block;
}
.view-ticket .row {
	margin: 0;
}
.right-box {
	padding: 0;
}
.count-quantity {
	margin-right: 10px;
	font-weight: 600;
}
.type-pass {
	font-weight: 400;
	margin-left: 5px;
}
.main-ticket-area {
	width: 280px;
	height: 400px;
}

.print-ticket-wrapper {
	height: 312px;
	width: 203px;
	background-color: #edeff0;
}

.print-ticket-wrapper .ticket-header img {
	width: 40px;
	height: 40px;
	margin-right: 5px;
}

.ticket-header .content h4 {
	font-size: 14px;
	padding: 2px 0 1px;
}

.print-ticket-wrapper hr {
	border: 1px solid #000000;
	margin: 3px;
}
.ticket-body .ticket-type h2 {
	font-size: 17px;
	color: #000;
	padding: 5px;
	font-weight: 600;
	font-family: 'Lato', sans-serif;
	margin-bottom: 0;
}
.ticket-body .ticket-valid-price h2 {
	font-size: 10px;
	padding: 5px;
	color: #000;
	font-weight: 600;
	font-family: 'Lato', sans-serif;
	margin-bottom: 5px;
}

.ticket-body .ticket-valid-price h2 span {
	font-weight: 500;
}
.barcode {
	margin-top: 10px;
	text-align: center;
}
.barcode img {
	width: 100%;
	height: 180px;
}
.barcode span {
	font-size: 10px;
}
.ticket-container-adjust-space {
	margin: 0 -10px;
}

/**
 * fixed pagination issue
 */
.div-pagination {
	width:auto
}

.the-pagination {
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

nav.ticket-pagination {
	width: auto;
	margin: initial;
	height: auto;
}
.page-item {
	margin: 0 5px;
	background-color: transparent;
	border:0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	font-size: 16px;
	font-family: 'Lato-Regular';
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: transparent;
    border-color:transparent;
}
.page-item.active {
	color: #fff;
	background-color: #007bff;
	border-radius: 50%;
}

.page-item span.page-link {
	margin: 0;
	padding: 0;
}
span.page-link.round-numbers {
    background: transparent;
    border: none;
}
.note {
	font-size: 14px;
    padding: 0 20px;
    margin: 20px 0 0 0;
}