.margin0 {
  margin: 0;
}
.ticket-generate-content {
  background: #ffffff;
}
.view-ticket h4 {
  text-align: center;
  font: 400 18px Lato;
  letter-spacing: -0.31px;
  color: #4C6070;
  margin: 0;
}
.ticket-card {
  background: no-repeat;
  border: 0;
}
.print-box {
  text-align: right;
  padding: 0;
  margin-bottom: 8px;
}
.print-box a {
  text-decoration: underline;
  font: Bold 14px/18px Lato;
  letter-spacing: 0;
  color: #1982C6;
  display: block;
  overflow: hidden;
}
.left-box {
  padding: 50px 0px 30px;
  color: #fff;
  text-align: left !important;
}
.view-ticket .passenger-type {
  font: Bold 16px/20px Lato;
  text-transform: uppercase;
  margin-top: 16px;
}
.left-box .origin, .left-box .destination {
  font: 400 16px/20px Lato;
  letter-spacing: 0.16px;
  margin: 25px 0 8px 0;
}
.left-box .origin-name {
  font: 900 24px/32px Lato;
  letter-spacing: 0.24px;
  color: #fff;
  border-bottom: solid 1px;
  padding: 0 0 13px 0;
  margin: 0 16px;
  width: 404px;
}
.left-box .destination.padding0.col {
  font: 400 16px/20px Lato;
  letter-spacing: 0.16px;
  margin: 8px 0 8px 0;
}
.left-box .destination-name {
  font: 900 24px/32px Lato;
  letter-spacing: 0.24px;
  color: #fff;
  border-bottom: solid 1px;
  padding: 0 0 13px 0;
  width: 404px;
  margin: 0 16px 5px 16px;
}
.pass-way {
  font-size: 18px;
}
.barcode-img {
  text-align: center;
  padding: 0 0 0 16px;
}
.barcode-img img {
  width: 300px;
  height: auto;
}
.barcode-text {
  font-family: "Lato-Regular", sans-serif;
  font-size: 20px;
  color: #001F30;
  text-align: center;
  padding: 8px;
}
.logo-img {
  text-align: center;
  padding: 36px 0 24px 16px;
}
.logo-img img {
  width: 164px;
  height: auto;
}
.bottom-content-box {
  font: 400 14px Lato;
  letter-spacing: 0.11px;
  color: #2D3338;
  margin-top: 30px;
}
.bottom-content-box img{
  float: left;
  margin-right: 10px;
}
.bottom-content-box h4 {
  font: 900 18px Lato;
  letter-spacing: -0.48px;
  color: #4B4B4B;
}
.bottom-content-box a {
  font: 700 11px/16px Lato;
  letter-spacing: 0;
  color: #279CF7;
  margin-top: 4px;
  display: block;
}
.view-ticket .row {
  margin: 0;
  display: flex;
}
.right-box {
  padding: 0;
}
.inputcheckbox {
  margin: 0 15px 0 0;
  float: left;
  padding: 0;
  position: relative;
}

.inputcheckbox input {
  position: absolute;
  opacity: 0;
  left: 18px;
  top: 0px;
  z-index: 99;
  width: 100%;
  cursor: pointer;
}

.inputcheckbox label {
  position: relative;
  cursor: pointer;
  font: 700 16px Lato;
  letter-spacing: -0.31px;
  color: #0077CC;
  overflow: hidden;
  display: block;
}

.inputcheckbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #1982C6;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  margin-top: -3px;
}
.inputcheckbox input:checked + label:before {
  content: '';
  border: 1px solid #1982C6;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  background: #1982C6;
}

.inputcheckbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.pull-left {
  float: left;
}
.ticket-heading {
  text-align: left;
  font: 700 19px/18px Lato;
  letter-spacing: -0.42px;
  color: #010101;
  margin: 0 0 0 20px;
  float: left;
}

.terms-conditions {
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  color: #444444;
  background-color: #F7F7F7;
  margin: 30px auto !important;
  width: 904px;
}
.view-ticket .terms-conditions h3 {
  text-align: left;
  font-family: Lato-Black, sans-serif;
  font-size: 16px;
  font-weight:bold;
  letter-spacing: 0;
  margin: 0 0 10px;
  overflow: hidden;
}
.expand-icon {
  float: right;
  position: relative;
  top: -33px;
  transition: all 0.4s ease;
}
a.expand-icon img {
  transform: rotateZ(-180deg);
}
a.expand-icon.down img {
  transform: rotateZ(0deg);
}
.terms-conditions a.more-btn {
  font: 900 14px/18px Lato;
  letter-spacing: 0;
  color: #279CF7;
  margin-left: 2px;
  text-decoration: none;
}
.terms-conditions p {
  text-align: left;
  font: 400 14px/18px Lato;
  letter-spacing: 0;
  color: #2F2F35;
}
.passenger-type-mobile, .ticket-expiration-mobile, .ticket-valid-mobile{
  display: none;
}
.ticket-expiration {
  font-size: 14px;
  float: right;
  display: inline;
  margin: -22px 16px 0 0;
}

.ticket-text {
  padding-left: 32px;
  margin-top: 32px !important;
}

.ticket-valid {
    margin-top: 32px !important;
    display: inline-block !important;
    padding-left: 14px
}



/*-- Mobile device --*/
@media (max-width: 991px) {
  .ticket-card {
    padding: 0;
    background-image: inherit !important;
    height: 470px !important;
  }
  .left-box {
    padding: 8px 0 0 0;
    background: #0461A9;
    border-radius: 8px;
  }
  .left-box .origin, .left-box .destination {
    margin: 8px 0 0 0 !important;
    font-size: 14px !important;
  }
  .left-box .origin-name, .left-box .destination-name {
    font-size: 16px !important;
    margin: 0 16px !important;
    padding: 0 !important;
  }
  .pass-way {
    font-size: 16px;
    margin: 0 0 0 16px;
  }
  .passenger-type {
    display: none;
  }
  .ticket-valid {
    display: none !important;
  }

  .passenger-type-mobile {
    float: left;
    margin: 16px 0 0 0;
    display: inline-block;
  }
  .ticket-expiration-mobile {
    float: right;
    display: inline;
    margin: -8px 0 8px 0;
  }

  .ticket-valid-mobile{
    float: left;
    margin: 16px 0 10px 0;
    display: inline-block;
  }
  .expiration-title {
    font-size: 14px;
  }
  .expiration-date {
    font-size: 14px;
  }
  .bottom-content-box br {
    display: none;
  }
  .bottom-content-box {
    margin-top: 0;
    padding-bottom: 30px;
  }
  .terms-conditions {
    margin-top: 0;
    width: 100%;
  }
  
}
